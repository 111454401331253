import styled from "styled-components";

export const CommonButton = styled.button`
    background-color: #2F4F4F;
    color: white;
    border: none;
    border-radius: 4px;
    margin: 10px;
    text-transform: capitalize;

    :hover {
        background-color: #3f7070;
        color: white;
    }

    :focus {
        box-shadow: none;
    }
`;

export const NavButtons = styled(CommonButton)`
    background-color: #0a0a0a;
`;

export const ButtonForm = styled(CommonButton)`
    margin: auto;
`;