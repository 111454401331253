import axios from "axios";

export const postCategoryFn = (setPostCategory, idCategoria) => {
    axios.get(`https://proyectoaepi.herokuapp.com/api/posts/categoria/${idCategoria}`)
        .then(response => {
            setPostCategory(response.data.posts);
        }).catch(error => {
            console.log(error);
        })
}

export const findCategorias = (setPostsCategoria, setCategoriasTotal, setOrderPost) => {
    axios.get('https://proyectoaepi.herokuapp.com/api/posts/categoria/')
        .then(response => {

            if (setOrderPost) {
                setOrderPost(response.data.categoriasDisponibles.sort())
            } else {
                setPostsCategoria(response.data.categoriasDisponibles)
                setCategoriasTotal(response.data.total);
            }

        }).catch(error => {
            console.log(error);
        })
}