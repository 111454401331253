import { useEffect } from "react";
import { ButtonForm } from "../../UI/buttons";
import { H1Title } from "../../UI/layouts";

const FormularioEditVista = ({ register, postEdit, setValue }) => {

    const { titulo, descripcion, contenido, categoria } = postEdit;

    useEffect(() => {
        if (postEdit) {
            setValue('titulo', titulo);
            setValue('descripcion', descripcion);
            setValue('contenido', contenido);
            setValue('categoria', categoria);
        }
    });

    return (
        <>
            <title>Blog - Editar Post: {titulo} </title>
            <H1Title>Editar Post: {titulo}</H1Title>
            <div className="form-group mb-4">
                <label className="form-label">Titulo</label>
                <input
                    type="text"
                    placeholder="Título"
                    className="form-control"
                    required={true}
                    {...register('titulo')}
                />
            </div>
            <div className="form-group mb-4">
                <label>Descripción</label>
                <input
                    type="text"
                    placeholder="Descripción"
                    className="form-control"
                    required={true}
                    {...register('descripcion')}
                />
            </div>
            <div className="form-group mb-4">
                <label>Contenido</label>
                <textarea
                    type="text"
                    placeholder="Contenido"
                    className="form-control"
                    required={true}
                    {...register('contenido')}
                />
            </div>
            <div className="form-group mb-4">
                <label>Categoria</label>
                <input
                    type="text-area"
                    placeholder="Categoria"
                    className="form-control"
                    required={true}
                    {...register('categoria')}
                />
            </div>
            <ButtonForm className="btn btn-block">Enviar</ButtonForm>
        </>
    );
}

export default FormularioEditVista;