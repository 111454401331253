import { useState, useEffect } from "react";
import ListadoPostVista from "./presentacionales/ListadoPostVista";
import { allPosts } from "../functions/functionsListadoPost";
import { H1Title, PSpinner } from "../UI/layouts";
import Pagination from "react-js-pagination";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const ListadoPost = () => {

    const [posts, setPosts] = useState([]);
    const [activePage, setCurrentPage] = useState(1);
    const postsPerPage = 2;

    const indexOfLastPost = activePage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => allPosts(setPosts), []);

    return (
        <>
            <title>Blog - Listado Posts</title>
            <div className="container">
                <div className="row">
                    <H1Title>Listado de Posts</H1Title>
                    {(posts.length <= 0)
                        ?
                        <>
                            <Loader
                                type="Triangle"
                                color="#2F4F4F"
                                height={100}
                                width={100}
                            />
                            <PSpinner>Cargando...</PSpinner>
                        </>
                        :
                        <>
                            {currentPosts.map(post => <ListadoPostVista post={post} key={post._id} />)}
                            < Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={activePage}
                                itemsCountPerPage={postsPerPage}
                                totalItemsCount={posts.length}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                            />
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default ListadoPost;