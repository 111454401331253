import { NavLink } from 'react-router-dom';

const ListadoPostVista = ({ post }) => {

    const { titulo, descripcion, categoria, createdAt, updatedAt, _id, } = post;

    return (
        <>
            <div className="col-sm-5 card text-center mb-4 shadow">
                <div className="col">
                    <div className="card h-100">
                        <NavLink to={`/post/${_id}`} exact style={{ margin: '10px' }}>
                            <img src='https://www.tokioschool.com/wp-content/uploads/2020/10/Lenguajes-de-programacion.jpg' className="card-img-top" alt="..." title={titulo} />
                        </NavLink>
                        <div className="card-body">
                            <NavLink to={`/post/${_id}`} exact >
                                <h5 className="card-title" style={{ display: 'inline-block' }}>{titulo}</h5>
                            </NavLink>
                            <p className="card-text">{descripcion}</p>
                            <div className="card-footer text-muted">
                                <h6 className="card-text mt-2">{updatedAt ? `Editado: ${updatedAt}` : `Publicado: ${createdAt}`}</h6>
                                <span>Categoria:
                                    <NavLink to={`/categoria/${categoria}`} exact> {categoria}</NavLink>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListadoPostVista;