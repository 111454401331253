import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Formulario from './components/Formulario';
import ListadoPost from './components/ListadoPost';
import Navbar from './components/Navbar';
import Post from './components/Post';
import FormularioEdit from './components/FormularioEdit';
import Categoriapost from './components/CategoriaPost';
import Categoria from './components/Categoria';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/" component={ListadoPost} />
          <Route exact path="/create" component={Formulario} />
          <Route exact path="/categorias" component={Categoria} />
          <Route exact path="/post/:idpost" component={Post} />
          <Route exact path="/edit/:idpost" component={FormularioEdit} />
          <Route exact path="/categoria/:idCategoria" component={Categoriapost} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;