import { useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect } from 'react-router-dom';

import { Form, FormDiv } from '../UI/layouts';
import FormularioVista from "./presentacionales/FormularioVista";
import { sendPost } from "../functions/functionsFormulario";

const Formulario = () => {

    const [sentData, setSentData] = useState(false);
    const { register, handleSubmit, reset } = useForm();

    const onSubmitPost = (data) => sendPost(data, setSentData, reset);

    return (
        <>
            {sentData && <Redirect to="/" />}
            <FormDiv className="container">
                <Form onSubmit={handleSubmit(onSubmitPost)}  >
                    <FormularioVista register={register} />
                </Form>
            </FormDiv>
        </>
    );
}

export default Formulario;