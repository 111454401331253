import axios from "axios";
import { Toast } from "../const/constantes";

export const sendPost = (data, setSentData, reset) => {
    axios({
        method: 'post',
        url: 'https://proyectoaepi.herokuapp.com/api/posts',
        data: data
    })
        .then((response) => {
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: `Post ${data.titulo} creado correctamente`
                });
                setSentData(true);
            }
        }, (error) => {
            console.log(error);
        });
    reset();
}

export const editPost = (data, setSentData, reset, props) => {
    data.id = props.match.params.idpost;
    axios({
        method: 'post',
        url: 'https://proyectoaepi.herokuapp.com/posts/update',
        data: data
    })
        .then((response) => {
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: `Post ${data.titulo} editado correctamente`
                });
                setSentData(true);
            }
        }, (error) => {
            console.log(error);
        });
    reset();
}

export const findPostToEdit = (props, setPostEdit) => {
    axios.get('https://proyectoaepi.herokuapp.com/api/posts')
        .then(response => {
            const postToEdit = response.data.posts.find(element => element._id === props.match.params.idpost);
            setPostEdit(postToEdit);
        }).catch(error => {
            console.log(error);
        })
}