import { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import PostVista from "./presentacionales/PostVista";
import { handleDeletePost, uniquePost } from "../functions/functionsPost";

const Post = (props) => {

    const [deletePost, setDeletePost] = useState(false);
    const [post, setPost] = useState([]);
    const idPost = props.match.params.idpost;

    useEffect(() => uniquePost(idPost, setPost), [idPost]);

    const handleClick = () => handleDeletePost(post, setDeletePost);

    return (
        <>
            { deletePost && <Redirect to="/" />}
            <PostVista post={post} handleClick={handleClick} />
        </>
    );
}

export default Post;