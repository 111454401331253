import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect } from 'react-router-dom';

import { Form, FormDiv } from '../UI/layouts';
import { editPost, findPostToEdit } from "../functions/functionsFormulario";
import FormularioEditVista from "./presentacionales/FormularioEditVista";

const FormularioEdit = (props) => {

    const [postEdit, setPostEdit] = useState({})
    const [sentData, setSentData] = useState(false);
    const { register, handleSubmit, reset, setValue } = useForm();

    const onSubmitPost = (data) => editPost(data, setSentData, reset, props);

    useEffect(() => findPostToEdit(props, setPostEdit), [props])

    return (
        <>
            {sentData && <Redirect to="/" />}
            <FormDiv className="container">
                <Form onSubmit={handleSubmit(onSubmitPost)} >
                    <FormularioEditVista register={register} onSubmitPost={onSubmitPost} postEdit={postEdit} setValue={setValue} />
                </Form>
            </FormDiv>
        </>
    );
}

export default FormularioEdit;