import { useEffect, useState } from "react";
import { findCategorias } from "../functions/functionsCategoria";
import CategoriaVista from "./presentacionales/CategoriaVista";

const Categoria = () => {

    const [postsCategoria, setPostsCategoria] = useState([]);
    const [categoriasTotal, setCategoriasTotal] = useState(0);

    useEffect(() => findCategorias(setPostsCategoria, setCategoriasTotal), []);

    return <CategoriaVista postsCategoria={postsCategoria} categoriasTotal={categoriasTotal} />;
}

export default Categoria;