import { useEffect, useState } from "react";
import { postCategoryFn } from "../functions/functionsCategoria";
import { H1Title } from "../UI/layouts";
import CategoriaPostVista from "./presentacionales/CategoriaPostVista";


const Categoriapost = (props) => {

    const [postCategory, setPostCategory] = useState([]);
    const idCategoria = props.match.params.idCategoria;

    useEffect(() => postCategoryFn(setPostCategory, idCategoria), [idCategoria]);

    return (
        <>
            <title>Blog - Categoria: {idCategoria}</title>
            <H1Title>Categoria: {idCategoria}</H1Title>
            <div className="container">
                <div className="row">
                    {postCategory.map(post => <CategoriaPostVista post={post} key={post._id} />)}
                </div>
            </div>
        </>
    );
}

export default Categoriapost;
