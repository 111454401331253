import { useState } from "react";
import { NavLink } from "react-router-dom";
import { findCategorias } from '../../functions/functionsCategoria';
import { H1Title, PSpinner, Table } from "../../UI/layouts";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CommonButton } from "../../UI/buttons";

const CategoriaVista = (postsCategoria) => {

    const [orderPost, setOrderPost] = useState([]);

    let categoryVariable = postsCategoria.postsCategoria;
    categoryVariable = (orderPost.length > 0) ? orderPost : categoryVariable;

    return (
        <>
            <title>Blog - Categorias Disponibles: {postsCategoria.categoriasTotal}</title>
            <H1Title>Categorias Disponibles: {postsCategoria.categoriasTotal}</H1Title>
            {(postsCategoria.postsCategoria.length <= 0)
                ?
                <>
                    <Loader
                        type="Triangle"
                        color="#2F4F4F"
                        height={100}
                        width={100}
                    />
                    <PSpinner>Cargando...</PSpinner>
                </>
                :
                <>
                    <CommonButton onClick={() => findCategorias(null, null, setOrderPost)} style={{ marginBottom: '40px' }} className="btn" >Ordenar, A - Z</CommonButton>
                    {categoryVariable.map(categoria => (
                        <NavLink to={`/categoria/${categoria}`} key={categoria} className="navlink-categoriaVista">
                            <Table className="table table-success table-hover">
                                <tbody>
                                    <tr>
                                        <td>{categoria}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </NavLink>
                    ))}
                </>
            }
        </>
    );
}

export default CategoriaVista;