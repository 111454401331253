import styled from "styled-components";

export const FormDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;
`;

export const NavContainer = styled.nav`
    background-color: #2F4F4F;
    padding: 15px;
    margin-bottom: 15px;
`;

export const H1Title = styled.h1`
    margin-top: 20px;
    margin-bottom: 40px;
`;

export const Table = styled.table`
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
    text-transform: capitalize;
`;

export const Form = styled.form`
    width: 80%;
`;

export const PSpinner = styled.p`
    font-size: 20px;
    color: #2F4F4F;
    margin-top: 10px;
`;

export const H1Post = styled(H1Title)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 60px;
    text-transform: capitalize;
    color: white;
    background-image: url('https://www.tokioschool.com/wp-content/uploads/2020/10/Lenguajes-de-programacion.jpg');
    background-size: cover;
`;