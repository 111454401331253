import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../const/constantes";

export const handleDeletePost = (post, setDeletePost) => {
    Swal.fire({
        title: `¿Borrar post ${post.titulo}?`,
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: `Si`,
    })
        .then((result) => {
            if (result.isConfirmed) {
                axios.get(`https://proyectoaepi.herokuapp.com/posts/delete/${post._id}`)
                    .then(response => {
                        if (response.status === 200) {
                            setDeletePost(true);
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                Toast.fire({
                    icon: 'success',
                    title: `Post ${post.titulo} borrado correctamente`
                });
            } else if (result.isDenied) {
                Toast.fire({
                    icon: 'error',
                    title: `Borrado cancelado`
                });
            }
        })
}

export const uniquePost = (idPost, setPost) => {
    axios.get('https://proyectoaepi.herokuapp.com/api/posts')
        .then(response => {
            const postUnique = response.data.posts.find(element => element._id === idPost);
            setPost(postUnique);
        }).catch(error => {
            console.log(error);
        })
}