import { NavLink } from "react-router-dom";
import { CommonButton } from "../../UI/buttons";
import { H1Post } from "../../UI/layouts";

const PostVista = ({ post, handleClick }) => {

    const { titulo, contenido, categoria, createdAt, updatedAt, _id } = post;

    return (
        <>
            <title>Blog - {titulo}</title>
            <div className="container text-center">
                <H1Post className="header">
                    <p style={{ fontSize: '60px' }}>{titulo}</p>
                </H1Post>
                <div className="row">
                    <div className="card">
                        <p style={{ marginTop: '20px' }}>{contenido}</p>
                    </div>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <span>
                        Categoria: <NavLink to={`/categoria/${categoria}`} exact> {categoria}</NavLink>
                    </span>
                    <p>{updatedAt ? `Editado: ${updatedAt}` : `Publicado: ${createdAt}`}</p>
                    <CommonButton onClick={handleClick} className="btn">Borrar</CommonButton>
                    <NavLink to={`/edit/${_id}`} exact>
                        <CommonButton className="btn">Editar</CommonButton>
                    </NavLink>
                </div>
            </div>
        </>
    );
}

export default PostVista;