import { NavLink } from 'react-router-dom';
import { NavButtons } from '../UI/buttons';
import { NavContainer } from '../UI/layouts';

const Navbar = () => {
    return (
        <>
            <NavContainer className="navbar-container">
                <NavLink to="/" exact>
                    <NavButtons className="btn">Posts</NavButtons>
                </NavLink>
                <NavLink to="/create">
                    <NavButtons className="btn">Crear Post</NavButtons>
                </NavLink>
                <NavLink to="/categorias">
                    <NavButtons className="btn">Categorias</NavButtons>
                </NavLink>
            </NavContainer>
        </>
    );
}

export default Navbar;